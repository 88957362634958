<script setup>
</script>

<template>
    <section class="text-white mt-20">
      <div class="w-full flex items-center justify-center">
        <div class="mx-auto max-w-3xl text-center">
          <h1
            class="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-3xl font-extrabold text-transparent"
          >
          Recursive Diamond Whitelist Verification
        </h1>
          <br/>
          <h3
            class="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-2xl font-extrabold text-transparent"
          >
            For Art & BTC Eco
          </h3>
  
          <!-- <p class="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
            First Generative Art on Fractal Bitcion
          </p> -->

<!-- 
          <div class="mt-8 flex flex-wrap justify-center gap-4">
          <button
              @click="checkWhiteList"
              class="block w-full rounded border px-12 py-3 text-sm font-medium cursor-pointer hover:bg-gray-200 hover:text-gray-800 focus:outline-none focus:ring active:text-opacity-75 sm:w-auto transition duration-300"
            >
              Check WhiteList
            </button>
          </div> -->

        </div>
      </div>

      
    </section>
</template>
